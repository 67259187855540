import { connect } from 'react-redux'
import { Container, Col, Row, Card, CardBody, CardTitle, CardHeader, CardFooter, Progress } from 'reactstrap'
import { Helmet } from 'react-helmet';

import { useState } from 'react'

import Header from './Header'
import CreditorsPre from './CreditorsPre'


const Home = ({ dashboard }) => {
    return (
        <div>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

            <Container>
            <Header />
            <CreditorsPre />
            </Container>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
