import Welcome from '../pages/welcome';
import Welcome2 from '../pages/welcome2';
import Welcome3 from '../pages/welcome3';
import Welcome4 from '../pages/welcome4';

const routes = [

   
    {
        path: "/4",
        component: Welcome4,
    },
    {
        path: "/3",
        component: Welcome3,
    },
    {
        path: "/2",
        component: Welcome2,
    },
  
    {
        path: "/",
        component: Welcome,
    },
]

export default routes;