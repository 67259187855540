import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'

import { Link, useLocation } from 'react-router-dom'

const MobileNav = ({ overview }) => {
    const location = useLocation();

    return (
        <div className="archk-v2-mobile-nav z-depth-1">
            <Row>
                <Col>
                    <Link className={location.pathname === `/${overview.matter.id}/v2` ? 'active' : ''} to={`/${overview.matter.id}/v2`}>
                        <i className="fa-solid fa-house-chimney"></i>
                        <div>Home</div>
                    </Link>
                </Col>
                <Col>
                    <Link className={location.pathname.includes('/case/') ? 'active' : ''} to={`/${overview.matter.id}/v2/case/info`}>
                        <i className="fa-solid fa-gavel"></i>
                        <div>Case</div>
                    </Link>
                </Col>
                <Col>
                    <Link className={location.pathname.includes('/support/') ? 'active' : ''} to={`/${overview.matter.id}/v2/support/faqs`}>
                        <i className="fa-solid fa-question-circle"></i>
                        <div>Support</div>
                    </Link>
                </Col>
                <Col>
                    <Link className={location.pathname.includes('/payment/') ? 'active' : ''} to={`/${overview.matter.id}/v2/payment/pay`}>
                        <i className="fa-solid fa-dollar-sign"></i>
                        <div>Payment</div>
                    </Link>
                </Col>
            </Row>
            {/* <Link to={`/${overview.matter.id}/v2`}>
                <li onClick={setSidebarClosed} className={location.pathname === `/${overview.matter.id}/v2` ? 'active' : ''}>
                    <div className='archk-v2-sidebar-left-icon'>
                        <i className="fa-solid fa-house-chimney"></i>
                    </div>
                    <div className='archk-v2-sidebar-left-text'>
                        Home
                    </div>
                </li>
            </Link>
            <li onClick={() => setSidebarOpen('case')} className={location.pathname.includes('case/') ? 'active' : ''}>
                <div className='archk-v2-sidebar-left-icon'>
                    <i className="fa-solid fa-gavel"></i>
                </div>
                <div className='archk-v2-sidebar-left-text'>
                    Case
                </div>
            </li>
            <li onClick={() => setSidebarOpen('support')} className={location.pathname.includes('support/') ? 'active' : ''}>
                <div className='archk-v2-sidebar-left-icon'>
                    <i className="fa-solid fa-question-circle"></i>
                </div>
                <div className='archk-v2-sidebar-left-text'>
                    Support
                </div>
            </li>
            <li onClick={() => setSidebarOpen('payment')} className={location.pathname.includes('payment/') ? 'active' : ''}>
                <div className='archk-v2-sidebar-left-icon'>
                    <i className="fa-solid fa-dollar-sign"></i>
                </div>
                <div className='archk-v2-sidebar-left-text'>
                    Payment
                </div>
            </li>
            <Link to={`/${overview.matter.id}/v2/chat`}>
                <li onClick={setSidebarClosed} className={location.pathname.includes('v2/chat') ? 'active' : ''}>
                    <div className='archk-v2-sidebar-left-icon'>
                        <i className="fa-solid fa-comments"></i>
                    </div>
                    <div className='archk-v2-sidebar-left-text'>
                        Chat
                    </div>
                </li>
            </Link> */}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        overview: state.dashboard.overview,
    };
};

export default connect(mapStateToProps, '')(MobileNav);