import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row, Container } from 'reactstrap';

import {  useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'

const docs = [
    {
        "_id": "67e55b1dcca93379c475d1e2",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816799188ba72f9dc01ee",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 1743479999,
        "name": "Bank Statements - Last 6 months. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d20a",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816849188ba72f9dc0231",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Child Support - IF you pay any OR receive any. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d1f6",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "6688167f9188ba72f9dc020c",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Collection letters -  IF you have any. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d360",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "6688168b9188ba72f9dc026b",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Credit & Asset Reports - WE WILL PULL THESE. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d338",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "6688168f9188ba72f9dc028b",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Credit Counseling Certificate. Here is the link for it: ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d34c",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816999188ba72f9dc02d1",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 1743479999,
        "name": "Paystubs - For the past 6 months. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d374",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "6688169f9188ba72f9dc02f7",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Photo ID",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d288",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816a49188ba72f9dc0320",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Prepaid Cards - We need your statements for these. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d29c",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816a89188ba72f9dc0344",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Questionnaire",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d2b0",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816ac9188ba72f9dc0362",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Real Estate - This is for any home or land that you own. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d2c4",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816b29188ba72f9dc037f",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Retirement Account Statements - These are fully protected but we need the statements for them. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d2d8",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816b79188ba72f9dc03a4",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Signed Retainers - This is for our fees. For your protection, federal law requires us to put them in writing. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d3a2",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816bd9188ba72f9dc03c8",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Social Security & Disability\tIncome - IF you receive any. And it is fully protected. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d3f3",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "66f84ba77e6b14d61168b87b",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Social Security Card",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d3b7",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816dc9188ba72f9dc03f6",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Tax Returns - For the past 2 years. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d3cb",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816e19188ba72f9dc0412",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Vehicle & Mobile Titles - We can pull these for you if you like. ",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    },
    {
        "_id": "67e55b1dcca93379c475d3df",
        "company": "631e5c687418496a7ab0cb24",
        "matter": "67e55b1dcca93379c475d021",
        "division": "6376b82424e2233278fa3571",
        "matter_document": "668816e59188ba72f9dc0430",
        "documents": [],
        "assigned_to": [
            "67c0c946bac868cb72c1d2db",
            "67a3bf9e30b07aa5d943e077"
        ],
        "division_departments": [],
        "status_marked_by": null,
        "expires_at": 0,
        "name": "Whole Life Insurance",
        "description": "",
        "status": "missing",
        "status_marked_at": 1743084317,
        "created_at": 1743084317,
        "updated_at": 1743084317,
        "__v": 0
    }
]

const Home = ({ dashboard }) => {

    const [redirect, setRedirect] = useState(false)
    const [selected, setSelected] = useState([])

    const onSetSelected = useCallback((val) => {
        let state = JSON.parse(JSON.stringify(selected))
        if(!state.includes(val)) {
            state.push(val)
        } else {
            state = state.filter(s => s !== val)
        }
        setSelected(state)
    }, [selected])

    const onFinish = useCallback(() => {

        setRedirect(`/${dashboard.overview.matter.id}/welcome/3`)
    }, [dashboard.overview.matter.id])

    const Item = ({ name, _id }) => {
        const checked = selected.includes(_id)
        return (
            <Col xs={12} md={6} lg={4} className='' >
                <Card 
                    className={`cursor-pointer ${checked ? 'bg-gradient-success text-white' : ''}`} 
                    onClick={() => onSetSelected(_id)}
                >
                    <CardBody className='archk-v2-doc-select-card-body'>
                        <div className="custom-control custom-checkbox w-100 mb-3">
                            <input
                                className="custom-control-input d-"
                                id={`archk-v2-${_id}`}
                                type="checkbox"
                                checked={checked}
                            />
                            <label className="custom-control-label" htmlFor={`archk-v2-${_id}`}>
                                {name}
                            </label>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    if(redirect) return <Redirect to={redirect} />

    return (
        <div className='archk-v2-onboarding'>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

           <Container>
                <div className='text-center ml-auto mr-auto mt-6'>
                    <h2 className='display-3 mb-0'>Let's Talk Docs</h2>
                    <p className='mb-5'>To get your case filed we'll need some document from you. Don't worry you don't need them handy this second, just select the documents from the list below you would be able to provide us within the next 7 days.</p>
                </div>
                </Container>

                <hr />
                <Container>

                <div className='ml-auto mr-automb-6'>
                    <Row>
                        {docs.map(d => (
                            <Item _id={d._id} name={d.name} />
                        ))}

                    </Row>
                </div>
            
            </Container>

            <hr />

            <div className='text-center'>
                <button onClick={() => setRedirect(`/${dashboard.overview.matter.id}/welcome`)} className='btn btn-outline-warning'>
                    <i className="fas fa-arrow-left mr-2" />
                    Back
                </button>
                <button 
                    onClick={onFinish}
                    style={{minWidth: 200}} 
                    className='btn btn-lg btn-success'
                >
                    Next <i className="fas fa-arrow-right ml-2" />
                </button>
            </div>
          
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
