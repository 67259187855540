import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import val from '../images/val.jpeg';

const Home = ({ dashboard }) => {

    return (
        <Card className='z-depth'>
            <CardHeader className='bg-gradient-warning '>
                <CardTitle className='mb-0 text-white text-center'>My Case Manager</CardTitle>
            </CardHeader>

            <CardBody cl>
                <Row>
                    <div className='col-auto align-self-center' style={{width: 75}}>
                        <img src={val} alt="..." className='w-100 z-depth-1' style={{borderRadius: '100%'}} />
                    </div>
                    <Col className='align-self-center'>
                        <p className='text-dark text-uppercase mb-0'>Valentina Monroy</p>
                        <p className='mt--2 mb-0'>valentina@fairmaxlaw.com</p>

                    </Col>
                </Row>

            </CardBody>

            <CardFooter>
                <p className='my-3'>Hi John & Shannon! It was so great to speak with you today I'm looking forward to helping out stop your foreclosure. I know you said you can have the documents by this Friday, that's perfect! Shoot me an email on you have them uploaded</p>
            </CardFooter>

            <CardFooter>
                <p className='text-sm mb-0 text-muted py-2'>Sent by Valentina on 3/28/2025 at 2:14 PM</p>
            </CardFooter>
            <CardFooter>
                <button className="btn btn-outline-info btn-block"><i className="fa-regular fa-comments mr-2" />Get Support</button>
            </CardFooter>
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
