import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';

import {  useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'

const Home = ({ dashboard }) => {

    const [redirect, setRedirect] = useState(false)

    const onFinish = useCallback(() => {

        setRedirect(`/${dashboard.overview.matter.id}/welcome/4`)
    }, [dashboard.overview.matter.id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div className='archk-v2-onboarding'>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

            <div className='text-center ml-auto mr-auto w-75 mt-6'>
                <h2 className='display-3 mb-0'>What is the best time to reach you?</h2>
                <p>We'll do our best to call you at the times that work best.</p>
            </div>

            <div className='text-center ml-auto mr-auto w-75 mb-6'>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <h2>John Maher</h2>

                                <form >

                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-radio-spouse-1-morning`}
                                            name="customRadio"
                                            type="radio"


                                        />
                                        <label className="custom-control-label" htmlFor={`archk-radio-spouse-1-morning`}>
                                            Morning (9 - 12)
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-radio-spouse-1-afternoon`}
                                            name="customRadio"
                                            type="radio"


                                        />
                                        <label className="custom-control-label" htmlFor={`archk-radio-spouse-1-afternoon`}>
                                            Mid Day (12 - 3)
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-radio-spouse-1-night`}
                                            name="customRadio"
                                            type="radio"


                                        />
                                        <label className="custom-control-label" htmlFor={`archk-radio-spouse-1-night`}>
                                            Late Afternoon (3 - 6)
                                        </label>
                                    </div>
                                </form>
                            </Col>
                            <Col md={6}>
                                <h2>Shannon Maher</h2>

                                <form >

                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-radio-spouse-2-morning`}
                                            name="customRadio"
                                            type="radio"


                                        />
                                        <label className="custom-control-label" htmlFor={`archk-radio-spouse-2-morning`}>
                                            Morning (9AM - 12PM)
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-radio-spouse-2-afternoon`}
                                            name="customRadio"
                                            type="radio"


                                        />
                                        <label className="custom-control-label" htmlFor={`archk-radio-spouse-2-afternoon`}>
                                            Mid Day (12PM - 3PM)
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-radio-spouse-2-night`}
                                            name="customRadio"
                                            type="radio"


                                        />
                                        <label className="custom-control-label" htmlFor={`archk-radio-spouse-2-night`}>
                                            Late Afternoon (3PM - 6PM)
                                        </label>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>

            <hr />

            <div className='text-center'>
                <button onClick={() => setRedirect(`/${dashboard.overview.matter.id}/welcome/2`)} className='btn btn-outline-warning'>
                    <i className="fas fa-arrow-left mr-2" />
                    Back
                </button>
                <button 
                    onClick={onFinish}
                    style={{minWidth: 200}} 
                    className='btn btn-lg btn-success'
                >
                    Next <i className="fas fa-arrow-right ml-2" />
                </button>
            </div>
          
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
