import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';

import ccc from '../images/ccc.svg';


const Home = ({ dashboard }) => {

    return (
        <Card className="text-center">
            <CardHeader>
                <CardTitle className="mb-0">Credit Counseling Course</CardTitle>
            </CardHeader>

            <CardBody>
                <img className="w-50" style={{height: 200}} src={ccc} alt="..." />
            </CardBody>

            <CardFooter>
                <p className="mb-0">Credit Counseling Course Needed</p>
            </CardFooter>
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
