import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Input, Row } from 'reactstrap';

import { useCallback, useState } from 'react';

import Val from '../../home/images/val.jpeg'

import moment from 'moment'

const Home = ({ dashboard }) => {

    const [message, setMessage] = useState('')

    const [messages, setMessages] = useState([
        {
            contact: 1,
            avatar: Val,
            text: 'Got it, can I submit those documents through this chat?',
            date: Math.floor(new Date() / 1000),
            name: 'John',
            direction: 'incoming'
        },
        {
            user: 2,
            avatar: Val,
            text: 'Not directly through the chat, but you can upload them in the Documents section of your portal. Let me guide you. Go to Case Details, then click upload documents.',
            date: Math.floor(new Date() / 1000),
            name: 'Valentina',
            direction: 'outgoing'

        },
        {
            id: 3,
            avatar: Val,
            text: 'If you have any other questions just let me know.',
            date: Math.floor(new Date() / 1000),
            name: 'Valentina',
            direction: 'outgoing'

        },
        {
            id: 4,
            avatar: Val,
            text: 'Happy to help!.',
            date: Math.floor(new Date() / 1000),
            name: 'Valentina',
            direction: 'outgoing'

        }
    ])

    const onSend = useCallback(() => {
        if(!message) return;

        const state = JSON.parse(JSON.stringify(messages))
        state.push({
            id: state[state.length -1].id + 1,
            avatar: Val,
            text: message,
            date: Math.floor(new Date() / 1000),
            name: 'Valentina',
            direction: 'outgoing'

        })

        setMessages(state)
        setMessage('')

        document.getElementById('archk-chat-send-input').focus()

        setTimeout(() => {
            const cardBody = document.getElementById('archk-chat-card-body')

            cardBody.scrollTo({
                top: cardBody.scrollHeight,
                behavior: 'smooth'
            });
        }, 300)
    }, [message, messages])

     //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') {
            e.preventDefault()
            onSend()
        }
	}, [onSend])

    return (
        <div className='archk-chat'>

            <Card className='archk-chat-card'>

                <CardBody id="archk-chat-card-body" className='archk-chat-card-body'>

                    {messages.map((m, index) => {

                        const prev = messages[index - 1]
                        const next = messages[index + 1]

                        let day = moment.unix(m.date).format('MM/DD/YYYY')
                        let lastDay = prev ? moment.unix(prev.date).format('MM/DD/YYYY') : '';

                        let time = moment.unix(m.date).format('h:mm A')
                        let nextTime = next ? moment.unix(next.date).format('h:mm A') : ''

                        let showTime = true;

                        if(next) {
                            if(time === nextTime) {
                                if(m.contact === next.contact) {
                                    showTime = false
                                } else if(m.user === next.user) {
                                    showTime = false
                                }
                                
                            }
                        }

                        return (
                            <div>
                                {day !== lastDay ? (
                                    <p className='text-center'>{day}</p>
                                ) : ''}

                                <div 
                                    className={`archk-chat-message archk-chat-message-${m.direction} ${m.direction === 'outgoing' ? ' ml-auto' : ''}`}>

                                

                                <Card className=''>
                                    <CardBody>
                                        <p className='text-sm mb-0'>{m.text}</p>
                                    </CardBody>
                                </Card>

                                {showTime ? (
                                    <div>
                                        {m.direction === 'outgoing' ? (
                                            <Row>
                                            
                                                <Col className='    '>
                                                    {time} - {m.name}
                                                </Col>
                                                <div className='col-auto text-right'>
                                                    Me
                                                    {/* <img className='archk-chat-avatar' alt="..." src={m.avatar} /> */}
                                                </div>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <div className='col-auto'>
                                                    <img className='archk-chat-avatar' alt="..." src={m.avatar} />
                                                </div>
                                                <Col>
                                                    {time} - {m.name}
                                                </Col>
                                            </Row>
                                        
                                        )}
                                    </div>
                                ) : ''}

                                </div>
                            </div>
                        )
                    })}

                </CardBody>

                <CardFooter className='archk-chat-input-wrapper position-relative'>
                    <Input 
                        id="archk-chat-send-input"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        type="textarea"
                        placeholder='Type your message here..'
                        onKeyDown={_handleKeyDown}
                    />

                    <i onClick={onSend} className="fas fa-paper-plane text-info" />
                </CardFooter>
                        
            </Card>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
