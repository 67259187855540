import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row } from 'reactstrap';

import { useState } from 'react';

import Chat from './Chat'

const Home = ({ dashboard }) => {


    return (
        <div>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>


                <Card>
                    <CardHeader>
                        <CardTitle className='mb-0'>Chat</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <div className='border-info border rounded bg-secondary p-3 '>
                            <Row>
                                <div className='col-auto' style={{width: 40}}>
                                    <i className="fas fa-info-circle text-info" style={{fontSize: 24}} />
                                </div>

                                <Col>
                                    <p className='mb-0'>Chat is the quickest way to get in touch with your assigned case manager and to get answers to any questions you may have.</p>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>

                 

                </Card>

                <Chat />



        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
