import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';

const Home = ({ dashboard }) => {

    return (
        <Card className='text-center'>
            <CardHeader>
                <CardTitle className='mb-0'>Upcoming Appointment</CardTitle>
            </CardHeader>

            <CardBody className='bg-secondary'>
                <i className="fa-regular fa-calendar-check text-gradient-success" style={{fontSize: 60}} />
                <h2 className='text-muted mt-3 mb-0'>April 2nd, 4:30PM</h2>
                <p className='mb-0 font-weight-bold'>Phone Call</p>

            </CardBody>

            <CardFooter>
                <p className='mb-0'>We will give you a call around this time to check in on your document progress.</p>
            </CardFooter>
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
