
import Home from '../pages/home';
import CaseInfo from '../pages/caseInfo';
import CaseCreditors from '../pages/caseCreditors';
import CaseSignatures from '../pages/caseSignatures';
import CaseFiles from '../pages/caseFiles';

import supportFAQs from '../pages/supportFAQs';
import SupportCase from '../pages/supportCase';



const routes = [

    {
        path: "/support/case",
        component: SupportCase,
    },
    {
        path: "/chat",
        component: SupportCase,
    },
    {
        path: "/support/faqs",
        component: supportFAQs,
    },
    {
        path: "/case/files",
        component: CaseFiles,
    },
    {
        path: "/case/signatures",
        component: CaseSignatures,
    },
    {
        path: "/case/creditors",
        component: CaseCreditors,
    },
    {
        path: "/case/info",
        component: CaseInfo,
    },

    {
        path: "/",
        component: Home,
    },

  
]

export default routes;