import img from './img.svg'

import { Badge, Card, CardHeader, CardTitle, Col, Row } from 'reactstrap'

const Employment = ({}) => {

    return (
        <div className=''>

            <div className='my-4 bg-white rounded border'>
                <Row className=' '>
                    <div className='col-auto border-right py-4' style={{width: 170}}>
                        <div className='px-4 pl-5'>
                            <img src={img} alt="..." className='w-100' />
                        </div>
                    </div>

                    <Col className='pr-4 py-4'>
                        <p>Review your currently listed employment below, if everything looks correct you are good to move on! If not click "Edit Employment" below to make adjustments to any jobs you <b className='text-underline'>currently have or have had in the past 6 months.</b></p>

                        <div className='text-riht pr-4'>
                            <button className='btn btn btn-outline-warning'><i className="fas fa-edit mr-2" /> Edit Employment</button>
                        </div>
                    </Col>
                </Row>
            </div>

            <Card className='card-no-shadow'>
                <CardHeader>
                    <CardTitle className='mb-0'> John Maher <small className='text-muted'><small>- Monthly Income: $4,288</small></small> <i className="fas fa-edit mr-2 text-warning float-right" style={{position: 'relative', top: 5}} /></CardTitle>
                </CardHeader>

                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Title</th>
                                <th>Monthly Income</th>
                                <th style={{width: 140}} className='text-right'>Current</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className='text-dark text-uppercase font-weight-bold'>Architeck LLC</td>
                                <td>Software Engineer</td>
                                <td>$3,500</td>
                                <td className='text-right text-success'>Yes</td>
                            </tr>
                            <tr>
                                <td className='text-dark text-uppercase font-weight-bold'>Uber</td>
                                <td>Driver</td>
                                <td>$788</td>
                                <td className='text-right text-success'>Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
               
            </Card>


            <Card className='card-no-shadow'>
                <CardHeader>
                    <CardTitle className='mb-0'>Shannon Maher <small className='text-muted'><small>- Monthly Income: $2,250</small></small> <i className="fas fa-edit mr-2 text-warning float-right" style={{position: 'relative', top: 5}} /></CardTitle>
                </CardHeader>

                <div className='table-responsive'>

                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Title</th>
                                <th>Monthly Income</th>
                                <th style={{width: 140}} className='text-right'>Current</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className='text-dark text-uppercase font-weight-bold'>Fairmax Law</td>
                                <td>Paralegal</td>
                                <td>$2,250</td>
                                <td className='text-right text-success'>Yes</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </Card>

            <hr />

            <div className='mb-5'>

                <div className="custom-control custom-checkbox mb-3">
                    <input
                        className="custom-control-input"
                        id={`archk-v2-employment-confirmation`}
                        type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor={`archk-v2-employment-confirmation`}>
                        I have confirmed the above information is correct
                    </label>
                </div>

                <button className='btn btn-success mt-2'>Next Section <i className="fas fa-arrow-right ml-2" /></button>

           </div>

        </div>
    )
}

export default Employment