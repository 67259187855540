import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row } from 'reactstrap';

import { useState } from 'react';

import faqs from './faqs';

const Home = ({ dashboard }) => {

    const [searchText, setSearchText] = useState('')

    let results = faqs;

    if(searchText) {
        const string = searchText.toLowerCase();
        const all = [];

        results.forEach(r => {
            let questions = r.questions.filter(q => {
                if(q.answer.toLowerCase().includes(string)) return true;
                if(q.question.toLowerCase().includes(string)) return true;
                return false;
            })

            if(questions.length) all.push({ ...r, questions })
        })

        results = all

    }

    const Question = ({ question, answer }) => {
        const [open, setOpen] = useState(false)

        return (
            <div className='border-bottom pl-4'>
                <p 
                    onClick={() => setOpen(!open)}
                    className='cursor-pointer py-4 mb-0 text-dark font-weight-bold'
                >
                    <i className="fas fa-angle-down mr-4" /> {question}
                </p>
                {open ? (
                    <p>{answer}</p>
                ) : ''}
            </div>
        )
    }

    return (
        <div>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

            <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>FAQs</CardTitle>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col md={12} lg={8} className="align-self-center">
                            <div className='border-info border rounded bg-secondary p-3 mb-4'>
                                <Row>
                                    <div className='col-auto' style={{width: 40}}>
                                        <i className="fas fa-info-circle text-info" style={{fontSize: 24}} />
                                    </div>

                                    <Col>
                                        <p className='mb-0'>Find answers to common questions or use the search bar to explore specific topics.</p>
                                    </Col>
                                </Row>
                            </div>
                            <div className='position-relative'>
                                <i className="fas fa-search" style={{position: 'absolute', left: 12, top: 10}} />
                                <Input 
                                    id="archk-v2-faq-search"
                                    style={{borderRadius: 12, paddingLeft: 37, maxWidth: 300}}
                                    placeholder='Search'
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                </CardBody>

                {results.map((section, i) => (
                    <CardBody key={i} className='pb-0 mb-4'>
                        <div className='border-left pt-3 pl-4 ml-4 pr-3 pb-0'>
                            <h2>{section.name}</h2>

                            {section.questions.map((q, ii) => (
                                <Question 
                                    key={ii}
                                    question={q.question}
                                    answer={q.answer}
                                />
                            ))}

                        </div>


                    </CardBody>
                ))}

            </Card>


        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
