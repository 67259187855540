const faqs = [
    {
      "name": "Credit Score",
      "questions": [
        {
          "question": "What happens to my credit score after filing?",
          "answer": "Your credit score will typically increase by about 100 points around six months after filing, though individual results can vary."
        },
        {
          "question": "Does bankruptcy affect my credit score?",
          "answer": "Yes, bankruptcy appears on your credit report and can initially lower your score, but many filers see improvement within 6 to 12 months as debts are discharged."
        },
        {
          "question": "How long does a bankruptcy stay on my credit report?",
          "answer": "A Chapter 7 bankruptcy can remain on your report for up to 10 years, while a Chapter 13 typically stays for up to 7 years. However, its impact on your score often diminishes over time."
        },
        {
          "question": "When can I start rebuilding my credit after bankruptcy?",
          "answer": "You can begin rebuilding immediately after your discharge. Making on-time payments, keeping credit card balances low, and reviewing your credit reports for errors are key steps."
        },
        {
          "question": "What strategies can help improve my credit score post-bankruptcy?",
          "answer": "Consider using secured credit cards, paying all bills on time, maintaining low balances, and periodically checking your credit report to ensure accuracy."
        },
        {
          "question": "Does the type of bankruptcy (Chapter 7 vs. Chapter 13) affect my credit differently?",
          "answer": "Chapter 7 and Chapter 13 both show on your credit report, but Chapter 7 remains for 10 years while Chapter 13 remains for 7 years. The time period can influence how quickly your score may recover."
        },
        {
          "question": "Will lenders see my bankruptcy on my credit report?",
          "answer": "Yes, any lender that pulls a full credit report will see the bankruptcy entry until it’s removed by the credit bureaus."
        },
        {
          "question": "Are there credit card offers specifically for individuals who have filed bankruptcy?",
          "answer": "Many lenders offer secured credit cards or credit-builder cards to people with damaged credit. These products help you rebuild your score with responsible use."
        },
        {
          "question": "Will paying off remaining debts after bankruptcy help my credit?",
          "answer": "Yes, staying current on any non-discharged debts and paying them off on time demonstrates financial responsibility, which can improve your score over time."
        },
        {
          "question": "Should I check my credit report after bankruptcy?",
          "answer": "Absolutely. Reviewing your credit report ensures discharged debts are marked correctly and there are no errors that could negatively impact your score."
        }
      ]
    },
    {
      "name": "Eligibility",
      "questions": [
        {
          "question": "Who qualifies for Chapter 7 bankruptcy?",
          "answer": "You must pass the means test, which compares your income to the median in your state. If your income is below the median or you have little disposable income, you may qualify."
        },
        {
          "question": "Can I file for Chapter 13 if I’m behind on my mortgage?",
          "answer": "Yes, Chapter 13 can help you catch up on mortgage payments over time while keeping your home."
        },
        {
          "question": "What is the bankruptcy means test?",
          "answer": "The means test looks at your average monthly income, expenses, and family size to determine if you can afford to repay debts. Failing the test typically means Chapter 7 is not an option, though certain exceptions apply."
        },
        {
          "question": "If my income is above the median, can I still qualify for Chapter 7?",
          "answer": "Possibly. Even if your income is above the median, allowable expenses might reduce your disposable income enough to meet the means test criteria."
        },
        {
          "question": "Is there a maximum debt limit for Chapter 13?",
          "answer": "Yes. Chapter 13 has limits on secured and unsecured debts. These amounts adjust periodically. If your debts exceed these limits, Chapter 13 may not be available."
        },
        {
          "question": "Can I file if I previously had a bankruptcy discharge?",
          "answer": "Yes, but there are waiting periods. For Chapter 7, you typically must wait 8 years from your last filing date. For Chapter 13, the waiting period can vary from 2 to 4 years depending on your previous filing."
        },
        {
          "question": "Do both spouses have to file bankruptcy jointly?",
          "answer": "Not necessarily. Spouses can file individually or jointly. However, household income and expenses typically count in the means test calculation when determining eligibility."
        },
        {
          "question": "Can self-employed individuals file for bankruptcy?",
          "answer": "Yes. Self-employed individuals and small business owners can file for Chapter 7 or Chapter 13, provided they meet eligibility requirements such as the means test and debt limits."
        },
        {
          "question": "Who qualifies for Chapter 11 bankruptcy?",
          "answer": "Chapter 11 is commonly used by businesses or individuals with very high debts that exceed Chapter 13 limits. There is no specific debt or income limit, but the process can be more complex and costly."
        },
        {
          "question": "What if I only need to restructure secured debts like a car loan?",
          "answer": "Chapter 13 may be an option, allowing you to catch up on or modify certain secured debts. However, you must show you have enough income to handle the repayment plan."
        }
      ]
    },
    {
      "name": "Assets",
      "questions": [
        {
          "question": "Will I lose my home or car in bankruptcy?",
          "answer": "Not necessarily. Many people can keep their home or car through exemptions or by staying current on payments, especially in Chapter 13."
        },
        {
          "question": "Can I keep any of my property?",
          "answer": "Yes, each state has exemption laws that protect certain property such as clothing, household items, tools of the trade, and retirement accounts."
        },
        {
          "question": "What if my car is worth more than the exemption limit?",
          "answer": "If your car’s equity exceeds the allowable exemption, you may need to pay extra to keep it or risk having it sold to pay creditors, depending on the type of bankruptcy."
        },
        {
          "question": "Are my bank accounts safe from seizure?",
          "answer": "Funds in bank accounts can be subject to review by the trustee. Any non-exempt funds could potentially be used to pay creditors."
        },
        {
          "question": "Will my retirement accounts be protected in bankruptcy?",
          "answer": "Most qualified retirement accounts, like 401(k)s and IRAs, are generally protected by federal and state exemptions."
        },
        {
          "question": "What if I own a second home or vacation property?",
          "answer": "A second property may not be fully covered by exemptions. In some cases, the trustee could sell it to pay creditors, depending on the equity and local exemption laws."
        },
        {
          "question": "How do I determine which bankruptcy exemptions apply to me?",
          "answer": "Each state has its own exemption laws, and some allow the use of federal exemptions. Consulting a bankruptcy attorney or trusted resource can help you choose the right set."
        },
        {
          "question": "What is a no-asset bankruptcy case?",
          "answer": "A no-asset case occurs when you have no non-exempt assets for the trustee to sell, meaning creditors generally receive nothing beyond what exemptions protect."
        },
        {
          "question": "How can bankruptcy help if I’m behind on my mortgage?",
          "answer": "Chapter 13 can help you catch up on missed payments over time, while Chapter 7 might temporarily stop foreclosure but doesn’t permanently resolve arrears."
        },
        {
          "question": "What happens to co-owned property or assets with a co-signer?",
          "answer": "Co-owned assets may still be subject to review. The trustee may look at your share of the equity, and co-signers remain responsible for any jointly held debt."
        },
        {
          "question": "Do I have to list all my property in the bankruptcy filings?",
          "answer": "Yes. You must fully disclose all assets to the court, even if you believe they are protected by exemptions, to ensure accuracy and compliance with bankruptcy rules."
        }
      ]
    },
    {
      "name": "Debts",
      "questions": [
        {
          "question": "What debts are discharged in bankruptcy?",
          "answer": "Most unsecured debts like credit cards, medical bills, and personal loans are discharged. Some debts like student loans, child support, and certain taxes are not dischargeable."
        },
        {
          "question": "Can bankruptcy eliminate student loans?",
          "answer": "Generally, student loans are not discharged unless you can prove undue hardship through a separate legal process."
        },
        {
          "question": "How are secured debts like mortgages or car loans handled?",
          "answer": "Secured debts are tied to collateral. In Chapter 7, you can surrender the property or reaffirm the debt. In Chapter 13, you may catch up on missed payments and keep the collateral if you follow your repayment plan."
        },
        {
          "question": "What happens to priority debts such as child support or alimony?",
          "answer": "Priority debts must be paid in full in Chapter 13 and cannot be discharged in Chapter 7. These include child support, alimony, and certain taxes."
        },
        {
          "question": "Are tax debts dischargeable?",
          "answer": "Some older income taxes may be discharged if they meet specific criteria, but most recent or payroll taxes are not dischargeable."
        },
        {
          "question": "Will I still owe any debts after bankruptcy?",
          "answer": "You may owe non-dischargeable debts like child support, certain tax debts, and most student loans. All other eligible debts are eliminated."
        },
        {
          "question": "Do I have to list all my debts in my bankruptcy filing?",
          "answer": "Yes, you must disclose all debts, even those you plan to keep paying or believe might not be dischargeable. Full disclosure is required by law."
        },
        {
          "question": "How does the discharge process work?",
          "answer": "After completing all filing requirements and court procedures, the bankruptcy court issues a discharge order, which legally wipes out eligible debts."
        },
        {
          "question": "What if I incurred new debt right before filing for bankruptcy?",
          "answer": "Recent debts, especially large purchases or cash advances, may be scrutinized by the court and could potentially be deemed non-dischargeable if found to be fraudulent."
        },
        {
          "question": "Will I lose any of my assets when filing for bankruptcy?",
          "answer": "It depends on the value of your assets and available exemptions. In many cases, people can keep necessary items like clothing, household goods, and tools of the trade."
        }
      ]
    },
    {
      "name": "Filing Process",
      "questions": [
        {
          "question": "How long does the bankruptcy process take?",
          "answer": "Chapter 7 typically takes about 4–6 months. Chapter 13 involves a repayment plan lasting 3–5 years."
        },
        {
          "question": "Do I need to go to court?",
          "answer": "You’ll need to attend a brief meeting with a trustee called the 341 meeting, but most filers never appear before a judge."
        },
        {
          "question": "What documents should I gather before filing?",
          "answer": "You’ll need recent pay stubs, tax returns, bank statements, credit card statements, and any other documentation related to your debts, assets, and expenses."
        },
        {
          "question": "Can I keep my car or home if I file for bankruptcy?",
          "answer": "It depends on your state’s exemption laws and whether you continue making payments on secured debts. In many cases, filers can keep certain essential assets."
        },
        {
          "question": "Which debts are discharged in bankruptcy?",
          "answer": "Most unsecured debts, like credit card balances and medical bills, are typically discharged. Certain debts—like student loans, child support, and most taxes—are usually not dischargeable."
        },
        {
          "question": "How does filing for bankruptcy affect my credit?",
          "answer": "A bankruptcy can remain on your credit report for 7–10 years, but it also provides a fresh start. Rebuilding credit involves responsibly managing new credit accounts over time."
        },
        {
          "question": "Is an attorney required to file bankruptcy?",
          "answer": "You are not legally required to hire an attorney, but professional guidance often helps ensure all documentation is accurate and you understand your rights and obligations."
        },
        {
          "question": "Will I have to notify my creditors?",
          "answer": "Yes, you must list all of your creditors in your bankruptcy petition. Once your filing is completed, the court will send notice to them."
        },
        {
          "question": "What is a means test?",
          "answer": "The means test determines whether you have sufficient income to pay back a portion of your debts. It helps decide whether you qualify for Chapter 7 or must file under Chapter 13."
        },
        {
          "question": "Do I need credit counseling before filing?",
          "answer": "Yes, you must complete a credit counseling course from an approved agency before filing, and a debtor education course before your debts are discharged."
        }
      ]
    },
    {
      "name": "Life After Bankruptcy",
      "questions": [
        {
          "question": "Can I get credit after bankruptcy?",
          "answer": "Yes, many people receive credit card offers soon after filing. You may start with secured cards and rebuild your credit over time."
        },
        {
          "question": "Can I buy a house after bankruptcy?",
          "answer": "Yes, you may be eligible for a mortgage within 2–4 years of discharge, depending on the loan type and your credit recovery."
        },
        {
          "question": "How long does bankruptcy remain on my credit report?",
          "answer": "Chapter 7 typically remains for 10 years from the filing date, and Chapter 13 remains for 7 years."
        },
        {
          "question": "Will my credit score improve after filing bankruptcy?",
          "answer": "It can, especially if you adopt responsible credit habits post-filing. While bankruptcy impacts your score, consistent on-time payments and low credit usage can help you rebuild."
        },
        {
          "question": "What steps can I take to rebuild my credit?",
          "answer": "Pay your bills on time, keep credit usage low, check your credit reports for errors, and consider using secured credit cards or credit-builder loans."
        },
        {
          "question": "Can I rent an apartment after bankruptcy?",
          "answer": "Yes. Landlords often check credit, but a bankruptcy filing does not automatically disqualify you. Having proof of steady income and reliable references can help."
        },
        {
          "question": "How do future employers view bankruptcy?",
          "answer": "Some employers do check credit history. Being honest about your situation and showing improved financial responsibility can help alleviate concerns."
        },
        {
          "question": "Is it possible to rebuild savings after bankruptcy?",
          "answer": "Yes. With fewer debt obligations, many people find it easier to budget and save. Creating a realistic financial plan can help you gradually rebuild savings."
        },
        {
          "question": "Can I start a business after bankruptcy?",
          "answer": "There is no rule preventing you from starting a business. However, obtaining business credit or loans may be more challenging initially, requiring patience and a solid plan."
        },
        {
          "question": "Will I face higher interest rates on loans after bankruptcy?",
          "answer": "Potentially. Lenders view bankruptcy as higher risk, which can lead to higher interest rates initially. As your credit improves, you may qualify for better rates."
        }
      ]
    },
    {
      "name": "Impact on Employment and Public Records",
      "questions": [
        {
          "question": "Will bankruptcy affect my job?",
          "answer": "Filing bankruptcy doesn’t affect your current employment, and employers cannot fire you solely because you filed."
        },
        {
          "question": "Is bankruptcy public record?",
          "answer": "Yes, bankruptcy is public record, but it's not commonly accessed unless someone is specifically searching for it."
        },
        {
          "question": "Can an employer deny me a job because of bankruptcy?",
          "answer": "Private employers generally cannot discriminate against you solely for filing bankruptcy. However, some positions that involve financial trust or security clearances may consider your financial history."
        },
        {
          "question": "Do I have to tell my current employer I filed for bankruptcy?",
          "answer": "Unless required by a specific job policy or security clearance procedure, you typically do not have to disclose your bankruptcy to your employer."
        },
        {
          "question": "Will a security clearance be affected by bankruptcy?",
          "answer": "Bankruptcy can factor into a security clearance review, but it does not automatically disqualify you. Demonstrating that you’ve responsibly addressed financial issues can sometimes be seen favorably."
        },
        {
          "question": "How long does bankruptcy remain on public record?",
          "answer": "Your bankruptcy filing stays on the court’s public docket indefinitely, but the credit report impact typically lasts 7 to 10 years depending on the chapter."
        },
        {
          "question": "Can anyone look up my bankruptcy case online?",
          "answer": "Court records are generally accessible via PACER (Public Access to Court Electronic Records), but there is a fee and someone must know your identifying details to locate your case."
        },
        {
          "question": "Will my bankruptcy appear in newspapers or online announcements?",
          "answer": "Some local jurisdictions or legal publications may publish a notice of bankruptcy. This varies by location and is not universally required."
        },
        {
          "question": "How do I handle concerns about bankruptcy during a job interview?",
          "answer": "If asked, provide a concise, honest explanation and emphasize steps you’ve taken to resolve financial challenges. You are not obligated to offer details unless the employer has a legitimate reason to inquire."
        },
        {
          "question": "Can my professional license be affected by bankruptcy?",
          "answer": "In most cases, filing bankruptcy does not automatically impact professional licenses, though certain regulatory bodies may review your financial history, especially if you hold a fiduciary role."
        }
      ]
    }
  ]
  

export default faqs