import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Progress, Row } from 'reactstrap';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const Home = ({ dashboard }) => {

    const ProgressBar = ({ percent, title, color, opacity }) => (
        <Row className='mb-3' style={{opacity: opacity ? opacity : 1}}>
            <div className="col-auto text-left" style={{width: 65}}>
                <span className='text-white'>{percent}%</span>
            </div>
            <Col>
                <div className='col-auto align-self-center'>
                    <div className={`progress-`}>

                        <Progress max="100" value={percent} color={color} style={{ height: 18, borderRadius: 20, marginBottom: 0}}  />
                        <p className='text font-weight text-white mb-0 mt-2'>{title}</p>
                    </div>
                </div>

            </Col>
        </Row>

    )

    const CheckMark = ({text}) => (
        <Row>
            <div className='col-auto' style={{width: 40}}>
                <i className="fas fa-check text-success" style={{fontSize: 24}} />
            </div>

            <Col>
                <p className='text-white'>{text}</p>
            </Col>
        </Row>
    )

    return (
        <Card style={{overflow: 'hidden'}}>
            <Row>
                <Col sm={12} md={6} lg={7} xl={7}>
                    <CardHeader>
                        <CardTitle className='mb-0'>Pre-Filing</CardTitle>
                    </CardHeader>

                    <CardBody>


                        <div className='border-info border rounded bg-secondary p-3'>
                            <Row>
                                <div className='col-auto' style={{width: 40}}>
                                    <i className="fas fa-info-circle text-info" style={{fontSize: 24}} />
                                </div>

                                <Col>
                                    <p className='mb-0'>To move your case forward, it's essential to provide accurate and complete creditor information.</p>
                                </Col>
                            </Row>
                        </div>

                        <p  className='my-4'>During the Pre-Filing stage, we need your assistance in collecting and submitting details about all creditors. This ensures a smooth start to your bankruptcy process.</p>

                        <button className='btn btn-lg btn-info'>
                            Submit Creditor Information <i className="fas fa-arrow-right ml-2" />
                        </button>



                    </CardBody>
                </Col>

                <Col sm={12} md={6} lg={5} xl={5} className='bg-info-light-2' style={{borderTopRightRadius: 8, borderBottomRightRadius: 8}}>
                    <div className='p-4'>
                        <p className='text-white'>During the Pre-Filing stage, we need your assistance in collecting and submitting details about all creditors. This ensures a smooth start to your bankruptcy process.</p>

                        <CheckMark text="Ensures all creditors are notified and included in your case." />
                        <CheckMark text="Helps prevent creditors from pursuing collections." />
                        <CheckMark text="Sets a solid foundation for the resolution of your debt." />
                    </div>

                </Col>
            </Row>

    </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
