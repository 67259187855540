import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';


import business from './images/business.svg';
import dependents from './images/dependents.svg';
import fire from './images/fire.svg';
import govt from './images/govt.svg';
import govtDebt from './images/govtDebt.svg';
import houseOwn from './images/houseOwn.svg';
import job from './images/job.svg';
import judge from './images/judge.svg';
import otherProperty from './images/otherProperty.svg';
import spouse from './images/spouse.svg';
import taken from './images/taken.svg';
import vehicle from './images/vehicle.svg';

const Welcome = ({ dashboard }) => {

    const [redirect, setRedirect] = useState(false)
    const [selected, setSelected] = useState([])

    const onSetSelected = useCallback((val) => {
        let state = JSON.parse(JSON.stringify(selected))
        if(!state.includes(val)) {
            state.push(val)
        } else {
            state = state.filter(s => s !== val)
        }
        setSelected(state)
    }, [selected])

    const onFinish = useCallback(() => {

        setRedirect(`/${dashboard.overview.matter.id}/welcome/2`)
    }, [dashboard.overview.matter.id])

    const IconCard = ({ prop, img, text }) => (
        <Col xs={6} md={4} lg={3} xl={2} >
            <Card 
                onClick={() => onSetSelected(prop)}
                className={selected.includes(prop) ? 'text-center mb-3 bg-gradient-info text-white' : 'text-center mb-3' }
            >
                <CardBody className='p'>
                    <div className='text-center'>
                        <img className='w-75' src={img} alt="..."/>
                    </div>
                    <p className='mt-3 text-sm'>{text}</p>
                </CardBody>
            </Card>
        </Col>
    )

    if(redirect) return <Redirect to={redirect} />

    return (
        <div className='archk-v2-onboarding'>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

            <Container className='' fluid>

                <div className='text-center ml-auto mr-auto w-75 my-6'>
                    <h2 className='display-3 mb-0'>Welcome! Let's take a second to get to know you!.</h2>
                    <p className='mb-0'><b className='text-underline'>Please Select All That Apply Below</b></p>

                </div>

                

                <Row className='archk-v2-onboarding-section'>
                    <IconCard 
                        prop="has_spouse"
                        img={spouse}
                        text="I am filing with my spouse"
                    />
                   
                    <IconCard 
                        prop="has_dependents"
                        img={dependents}
                        text="I have at least 1 dependant"
                    />
                    <IconCard 
                        prop="has_vehicle"
                        img={vehicle}
                        text="I own, am paying on, or leasing a vehicle"
                    />
                    <IconCard 
                        prop="has_home"
                        img={houseOwn}
                        text="I own a home or am paying a mortgage on a home"
                    />
                    <IconCard 
                        prop="has_other_property"
                        img={otherProperty}
                        text="I own property I do not live at"
                    />
                    <IconCard 
                        prop="has_job"
                        img={job}
                        text="I been employed in the last 6 months"
                    />
                    <IconCard 
                        prop="has_judgement_garnishment"
                        img={judge}
                        text="I have a judgement, garnishment, or lean out against me"
                    />
                    <IconCard 
                        prop="has_business"
                        img={business}
                        text="I own a business"
                    />
                    <IconCard 
                        prop="has_govt_assistance"
                        img={govt}
                        text="I get some form of government assistance"
                    />
                    <IconCard 
                        prop="has_govt_debt"
                        img={govtDebt}
                        text="I OWE money to the government such as over due taxes"
                    />
                    <IconCard 
                        prop="has_unexpected_loss"
                        img={fire}
                        text="In the last year I have a loss from fire, disaster, or gambling"
                    />
                    <IconCard 
                        prop="has_seizure"
                        img={taken}
                        text="In the last year I have had property foreclosed, taken, or repossessed"
                    />

                </Row>

                <hr />

                <div className='text-center'>
                    <button 
                        onClick={onFinish}
                        style={{minWidth: 300}} 
                        className='btn btn-lg btn-success'
                    >
                        Next <i className="fas fa-arrow-right ml-2" />
                    </button>
                </div>

            </Container>
            
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Welcome);
