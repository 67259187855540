import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row, Container, FormGroup, Input } from 'reactstrap';

import {  useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'

const Home = ({ dashboard }) => {

    const [redirect, setRedirect] = useState(false)
    const [income, setIncome] = useState('')
    const [debt, setDebt] = useState('')

    const onFinish = useCallback(() => {

        setRedirect(`/${dashboard.overview.matter.id}/v2`)
    }, [dashboard.overview.matter.id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div className='archk-v2-onboarding'>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

           <Container>
                <div className='text-center ml-auto mr-auto mt-6'>
                    <h2 className='display-3 mb-0'>Last Step - You're Almost There!</h2>
                    <p className='mb-5'>Please enter your average monthly household income and your total estimated debts below</p>
                </div>
                </Container>

                <hr />

                <Container>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <label className='form-control-label'>Average Monthly Household Income</label>
                                        <Input 
                                            type="number"
                                            value={income}
                                            onChange={e => setIncome(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <label className='form-control-label'>Estimated Total Debts</label>
                                        <Input 
                                            type="number"
                                            value={debt}
                                            onChange={e => setDebt(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                
                </Container>

            <hr />

            <div className='text-center'>
                <button onClick={() => setRedirect(`/${dashboard.overview.matter.id}/welcome/3`)} className='btn btn-outline-warning'>
                    <i className="fas fa-arrow-left mr-2" />
                    Back
                </button>
                <button 
                    onClick={onFinish}
                    style={{minWidth: 200}} 
                    className='btn btn-lg btn-success'
                >
                    Finish <i className="fas fa-check-circle ml-2" />
                </button>
            </div>
          
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
