
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "../layout/routes";

import Sidebar from './Sidebar'
import MobileNav from './MobileNav'

import Header from './Header'

import Circle from 'components/markup/loading/Circle'

import { fetchOverview } from 'store/functions/dashboard/index'

const Dashboard = ({ dashboard, match }) => {

    const location = useLocation();

    const [redirect, setRedirect] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const fetchData = useCallback(async () => {
        const id = match.params.matter;
        if(!id) setRedirect('/launchpad')
        fetchOverview(id)
    }, [match.params.matter])

    useEffect(() => {
        fetchData()

        window.addEventListener('focus', fetchData);

        return () => {
            window.removeEventListener('focus', fetchData)
        }
    }, [fetchData])

    useEffect(() => {
        window.scrollTo(0,0)

        document.body.classList.add('bg-secondary')

        return () => {
            document.body.classList.remove('bg-secondary')
        }
    }, [location.pathname])

    if(dashboard.err) return (
        <p className='text-center py-3 px-4 text-warning text-white font-weight-bold'>
            <i className="fas fa-info-circle mr-2" /> An unexpected error has occurred loading this page. <br />Please try refreshing your browser.
        </p>
    )

    if(redirect) return <Redirect to={'/launchpad'} />
   
    if(!dashboard.overview) return (
        <div className='py-6'><Circle /></div>
    )

    return (
        <div className="layout-auth" >

            <Sidebar 
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />

            <MobileNav />


            <div className='archk-v2-main'>

                <Header 
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <Switch>
                    {routes.map((prop, key) => {

                        return (
                            <Route
                                exact path={`/:matter/v2${prop.path}`}
                                component={prop.component}
                                key={key}
                            />
                        )
                    })}
                    {/* <Redirect from="*" to="/dashboard" /> */}
                </Switch>
            </div>
        </div>
    );

}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Dashboard);