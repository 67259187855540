import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import assets from '../images/assets.svg';
import business from '../images/business.svg';
import debts from '../images/debts.svg';
import employment from '../images/employment.svg';
import expenses from '../images/expenses.svg';
import family from '../images/family.svg';
import financialAccounts from '../images/financialAccounts.svg';
import govt from '../images/govt.svg';
import house from '../images/house.svg';
import legal from '../images/legal.svg';
import transfers from '../images/transfers.svg';

const Home = ({ dashboard }) => {

    const InfoCard = ({ img, text, complete }) => (
        complete ? '' : 
        <Col xs={12} md={4} lg={3} xl={3} >
            <Card 
                style={{opacity: complete ? .8 : 1}}
                className={`cursor-pointer text-center mb-3 ${complete ? 'bg-gradient-success text-white' : ''}`}
            >
                <CardBody className='p'>
                    <div className='text-center'>
                        <img className='w-75' style={{height: 150}} src={img} alt="..."/>
                    </div>
                    <p className='mt-4 text-sm'>{complete ? <i className="fas fa-check-circle mr-2" /> : ''} {text}</p>
                </CardBody>
            </Card>
        </Col>
    )

    return (
        <Card>
            <CardHeader>
                <div className='text-center'>
                    <h2 className='display-3 mb-0'>Pickup Where You Left Off</h2>
                    <h2 className='text-muted'>We Still Need Some Information In The Following Areas:</h2>
                </div>
            </CardHeader>

            <CardBody className='bg-secodary'>

                <Row>
                    <InfoCard img={family} text="My Family" />
                    <InfoCard img={employment} text="Employment" complete={true} />
                    <InfoCard img={house} text="Property" />
                    <InfoCard img={financialAccounts} text="Financial Accounts" complete={true} />
                    <InfoCard img={assets} text="Assets" />
                    <InfoCard img={debts} text="Debts" />
                    <InfoCard img={transfers} text="Transfers" complete={true} />
                    <InfoCard img={legal} text="Legal Actions" complete={true} />
                    <InfoCard img={business} text="Businesses" complete={true} />
                    <InfoCard img={govt} text="Govt Assistance" complete={true} />
                    <InfoCard img={expenses} text="Expenses"  complete={true} />
                </Row>

            </CardBody>
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
