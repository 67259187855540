import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Progress } from 'reactstrap';
import { Helmet } from 'react-helmet';

import A from 'components/markup/links/A'

const Signatures = ({ overview }) => {

    const percent = overview.totalForms ? parseFloat(((overview.completedForms / overview.totalForms) * 100).toFixed(1)) : 100

    return (
        <div>

            <Helmet>
                <title>Information</title>
                <meta name="description" content="Information" />
            </Helmet>

            <Card className='card-no-shadow'>
                <CardBody>
                    <p className='mb-0'><i className="fas fa-info-circle mr-2 text-info" /> On this page you can view any requests for information that have been sent out to you.. </p>

                    <div className="progress-wrapper pt-3">
                        <div className="progress-info">
                            <div className="progress-label">
                                <span>Information Items Finalized</span>
                            </div>
                            <div className="progress-percentage">
                                <span>{percent}%</span>
                            </div>
                        </div>
                        <Progress max="100" value={percent < 2 ? 2 : percent} color="success" />
                    </div>

                </CardBody>
            </Card>

            <Card className='card-no-shadow'>
                <CardHeader>
                    <CardTitle className='mb-0'>Information Requests</CardTitle>
                </CardHeader>

                {overview.progressSignatures.length ? (
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Form name</th>
                                    <th>Completion Percent</th>
                                    <th className='text-right'>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {overview.progressForms.map((d, i) => (
                                    <tr key={d._id}>
                                        <td>
                                        {d.link ? (
                                            <A className="text-dark" href={d.link}>{d.form_name}</A>
                                            ) : <span>{d.form_name}</span>}
                                        </td>
                                        <td className='text-capitalize'>
                                            {d.completion_percent}%
                                        </td> 
                                        <td className='text-right'>
                                            {d.link ? (
                                                <A href={d.link} className='btn btn-success btn-sm'><i className="fas fa-link mr-2" /> Input Information </A>
                                            ) : <i className="fas fa-check text-success" />}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                
                ) : (
                    <CardBody>
                        <p className='text-sm mb-0 text-dark font-weight-bold'>
                            <i className="fas fa-check text-success mr-2" /> All Set! No Forms Are Currently Awaiting Input
                        </p>
                    </CardBody>
                )}

            </Card>

           

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(Signatures);