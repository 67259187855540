import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, CardFooter, Input, Row } from 'reactstrap';

import { useState } from 'react';
import DragAndDrop from 'components/functional/uploads/DragAndDrop2';

import docs from './docs'

const Home = ({ dashboard }) => {

    return (
        <div>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

            <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>My Case Documents</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className='border-info border rounded bg-secondary p-3'>
                        <Row>
                            <div className='col-auto' style={{width: 40}}>
                                <i className="fas fa-info-circle text-info" style={{fontSize: 24}} />
                            </div>

                            <Col>
                                <p className='mb-0'>This page shows all the documents still needed and the ones that have been approved for your cae. Any document not marked as pending or approved means there is still action needed in this area regarding your case</p>
                            </Col>
                        </Row>
                    </div>
                </CardBody>

            </Card>

            <Row>
                <Col md={7}>
                    
                        {docs.slice(4, docs.length).map((d, i) => (
                            <Card key={d._id}>

                                <CardHeader>
                                    <Row>
                                        <div className='col-auto align-self-center' style={{width: 66}}>
                                            <i style={{fontSize: 30}} className="fas fa-info-circle bg-gradient-warning text-white p-3 rounded mr-2 " />
                                        </div>
                                        <Col className='align-self-center'>
                                            <h2 className='text-whit mb-0'> {d.name}</h2>
                                            {/* <p className='mb-0 mt--2 text-whit'>{d.description}</p> */}
                                            <p className='mb-0 mt--2 text-whit'>No documents uploaded yet.</p>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                

                                    { i === 1 ? (
                                        <div>
                                                <CardBody className='bg-secondary text-center'>
                                                <p className='text-muted font-weight-bold mb-0'>My Uploaded Documents</p>

                                                <div className='mt-2'>
                                                <span className='px-3 mt-0 py-2 mr-3  text-white mb-3 d-inline-block rounded border bg-purple'>
                                                    PayStubs May.pdf <i className="fas fa-edit ml-3 cursor-pointer" /> 
                                                </span>
                                                <span className='px-3 mt-0 py-2 mr-3  text-white mb-3 d-inline-block rounded border bg-purple'>
                                                    PayStubs June.pdf <i className="fas fa-edit ml-3 cursor-pointer" /> 
                                                </span>
                                                <span className='px-3 mt-0 py-2 mr-3  text-white mb-3 d-inline-block rounded border bg-purple'>
                                                    PayStubs July.pdf <i className="fas fa-edit ml-3 cursor-pointer" /> 
                                                </span>
                                                <span className='px-3 mt-0 py-2 mr-3  text-white mb-3 d-inline-block rounded border bg-purple'>
                                                    PayStubs August.pdf <i className="fas fa-edit ml-3 cursor-pointer" /> 
                                                </span>
                                                <span className='px-3 mt-0 py-2 mr-3  text-white mb-3 d-inline-block rounded border bg-purple'>
                                                    PayStubs September.pdf <i className="fas fa-edit ml-3 cursor-pointer" /> 
                                                </span>
                                                <span className='px-3 mt-0 py-2 mr-3  text-white mb-3 d-inline-block rounded border bg-purple'>
                                                    PayStubs October.pdf <i className="fas fa-edit ml-3 cursor-pointer" /> 
                                                </span>
                                            </div>
                                            </CardBody>

                                        </div>
                                    ) : ''}
                                    {/* <p className='text-muted mb-0 mt--2'>No Files Found</p> */}


                                    

                                    {/* <p className='text-muted mb-0 mt--2'>PayStubs.pdf</p> */}
                                    {/* <p className='text-muted mb-0 mt--2'>PayStubs June.pdf</p> */}
                                    {/* <p className='text-muted mb-0 mt--2'>PayStubs July.pdf</p> */}

                                <CardFooter  className='border bg-secondar  pb-2'>
                                    
                                            

                                            {/* <div className=''>
                                                <p className='text-dark font-weight-bold'>Uploaded Documents</p>
                                                
                                                <p className='text-muted'>Empty List</p>


                                            </div> */}

                                            {/* <p className='pt-3'>No documents have been uploaded to this category yet.</p> */}


                                        <div className=''>

                                            <DragAndDrop
                                                zone_id={`archk-document-center-${d._id}`}
                                                url={`/v1/documents`}
                                                onUploadStarted={() => {
                                                    // toast.info(`Document upload started.`)
                                                }}
                                                onSuccess={(file, request) => {
                                                    // fetchData()
                    
                                                    if(request && request.data && request.data.status === 'succeeded') {
                                                        // toast.success(`Document Upload Succeeded`)
                                                    } else if(request) {
                                                        // toast.error(`Something went wrong uploading a document, please try again.`)
                                                    }
                                                }}
                                                onError={(e) => {
                                                    // toast.error('Document could not be uploaded, please try again or check the integrity of the file')
                                                }}
                                                resizePixels={1200}
                    
                                                extraData={{
                                                    // matter: overview.matter._id,
                                                    // matter_document: selectedDocument.matter_document,
                                                    // matter_document_upload: selectedDocument.matter_document_upload,
                                                }}
                                            />
                                        </div>

                                </CardFooter>

        
                            </Card>
                        ))}

                </Col>
                <Col md={5}>
                    <Card>


                        <CardHeader className='bg-gradient-info text-center'>
                            <CardTitle className='mb-0 text-white  mb-0'><i className="fas fa-question-circle mr-2" />  Documents Pending Approval</CardTitle>
                            <p className='text-sm mb-0 text-white text-white'>Our staff is currently reviewing the following documents</p>
                        </CardHeader>

                        <CardBody>
                        {docs.slice(2, 4).map(d => (
                            <Card className='mb-3'>
                                <CardBody key={d._id} className='border bg-secondary rounded'>
                                    <Row>
                                        <div className='col-auto align-self-center' style={{width: 66}}>
                                            <i style={{fontSize: 30}} className="fas fa-clock bg-gradient-info text-white p-3 rounded mr-2 " />
                                        </div>
                                        <Col className='align-self-center'>
                                            <h2 className='text-whit mb-0'> {d.name}</h2>
                                            <p className='mb-0 mt--2 text-whit'>Documents successfully reviewed and approved as good to go!</p>
                                        </Col>
                                    </Row>
                                    
                                </CardBody>
                            </Card>
                        ))}

                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className='bg-gradient-success text-center'>
                            <CardTitle className='mb-0 text-white  mb-0'><i className="fas fa-check-circle mr-2" />  Approved Documents</CardTitle>
                            <p className='text-sm mb-0 text-white text-white'>All set on these, no more action needed here</p>
                        </CardHeader>

                        <CardBody>
                        {docs.slice(0, 2).map(d => (
                            <Card className='mb-3'>
                                <CardBody key={d._id} className='border bg-secondary rounded'>
                                    <Row>
                                        <div className='col-auto align-self-center' style={{width: 66}}>
                                            <i style={{fontSize: 30}} className="fas fa-check-circle bg-gradient-success text-white p-3 rounded mr-2 " />
                                        </div>
                                        <Col className='align-self-center'>
                                            <h2 className='text-whit mb-0'> {d.name}</h2>
                                            <p className='mb-0 mt--2 text-whit'>Documents successfully reviewed and approved as good to go!</p>
                                        </Col>
                                    </Row>
                                    
                                </CardBody>
                            </Card>
                        ))}

                        </CardBody>


                    </Card>
                </Col>
            </Row>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
