import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';

import faq from '../images/faq.svg';


const Home = ({ dashboard }) => {

    return (
        <Card className="text-center bg-gradient-purple">
            <CardHeader className='bg-gradient-purple border-none'>
                <CardTitle className="mb-0 text-white">Questions?</CardTitle>
            </CardHeader>

            <CardBody>
                <img className="w-50" style={{height: 200}} src={faq} alt="..." />
            </CardBody>

            <CardFooter className='bg-gradient-purple border-none'>
                <p className="mb-0 text-white">Check Out Our FAQ</p>
            </CardFooter>
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
