import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Col, Row } from 'reactstrap';


const SidebarLarge = ({ overview, sidebarOpen }) => {
    const location = useLocation();

    const [tab, setTab] = useState('case')

    const setSidebarOpen = useCallback((t) => {
        setTab(t)
        document.body.classList.add('archk-v2-sidebar-full')
    }, [])

    const setSidebarClosed = useCallback(() => {
        document.body.classList.remove('archk-v2-sidebar-full')
    }, [])

    const TabItem = ({ link, icon, text }) => (
        <Link to={`/${overview.matter.id}${link}`}>
            <li 
                onClick={setSidebarClosed} 
                className={`text-left ${location.pathname.includes(link) ? 'active' : ''}`}
            >

                <i className={`${icon} archk-v2-sidebar-right-icon`}></i>

                <span className='archk-v2-sidebar-right-text font-weight-normal'>
                    {text}
                </span>
            </li>
        </Link>
    )

    return (
        <div className={`archk-v2-sidebar custom-shadow-1 ${sidebarOpen ? 'open' : ''}`}>

            <Row>
                <div className='archk-v2-sidebar-left col-auto text-center' style={{width: 95}}>

                    <ul>
                        <Link to={`/${overview.matter.id}/v2`}>
                            <li onClick={setSidebarClosed} className={location.pathname === `/${overview.matter.id}/v2` ? 'active' : ''}>
                                <div className='archk-v2-sidebar-left-icon'>
                                    <i className="fa-solid fa-house-chimney"></i>
                                </div>
                                <div className='archk-v2-sidebar-left-text'>
                                    Home
                                </div>
                            </li>
                        </Link>
                        <li onClick={() => setSidebarOpen('case')} className={location.pathname.includes('case/') ? 'active' : ''}>
                            <div className='archk-v2-sidebar-left-icon'>
                                <i className="fa-solid fa-gavel"></i>
                            </div>
                            <div className='archk-v2-sidebar-left-text'>
                                Case
                            </div>
                        </li>
                        <li onClick={() => setSidebarOpen('support')} className={location.pathname.includes('support/') ? 'active' : ''}>
                            <div className='archk-v2-sidebar-left-icon'>
                                <i className="fa-solid fa-question-circle"></i>
                            </div>
                            <div className='archk-v2-sidebar-left-text'>
                                Support
                            </div>
                        </li>
                        <li onClick={() => setSidebarOpen('payment')} className={location.pathname.includes('payment/') ? 'active' : ''}>
                            <div className='archk-v2-sidebar-left-icon'>
                                <i className="fa-solid fa-dollar-sign"></i>
                            </div>
                            <div className='archk-v2-sidebar-left-text'>
                                Payment
                            </div>
                        </li>
                        <Link to={`/${overview.matter.id}/v2/chat`}>
                            <li onClick={setSidebarClosed} className={location.pathname.includes('v2/chat') ? 'active' : ''}>
                                <div className='archk-v2-sidebar-left-icon'>
                                    <i className="fa-solid fa-comments"></i>
                                </div>
                                <div className='archk-v2-sidebar-left-text'>
                                    Chat
                                </div>
                            </li>
                        </Link>
                    </ul>
                </div>

                {tab === 'case' ? (
                    <Col className='archk-v2-sidebar-right text-left'>
                        <div className='archk-v2-sidebar-right-title text-dark border-bottom'>
                            My Case
                        </div>

                        <ul>
                            <TabItem link="/v2/case/info" icon="fa-solid fa-scale-balanced" text="Case Info" />
                            <TabItem link="/v2/case/files" icon="fa-solid fa-file-export" text="Files" />
                            <TabItem link="/v2/case/signatures" icon="fa-solid fa-signature" text="Signatures" />
                            <TabItem link="/v2/case/creditors" icon="fa-solid fa-hand-holding-dollar" text="Creditors" />
                        </ul>
                    </Col>
                ) : tab === 'support' ? (
                    <Col className='archk-v2-sidebar-right text-left'>
                        <div className='archk-v2-sidebar-right-title text-dark border-bottom'>
                            Support
                        </div>

                        <ul>
                            <TabItem link="/v2/support/faqs" icon="fa-solid fa-question" text="FAQs" />
                            <TabItem link="/v2/support/case" icon="fa-regular fa-comments" text="Case Support" />
                            {/* <TabItem link="/v2/support/education" icon="fa-solid fa-school" text="Education" /> */}
                        </ul>
                    </Col>
                ) : tab === 'payment' ? (
                    <Col className='archk-v2-sidebar-right text-left'>
                        <div className='archk-v2-sidebar-right-title text-dark border-bottom'>
                            Payment
                        </div>

                        <ul>
                            <TabItem link="/v2/payment/pay" icon="fa-solid fa-dollar-sign" text="Make Payment" />
                            <TabItem link="/v2/payment/history" icon="fa-regular fa-credit-card" text="History" />
                            <TabItem link="/v2/payment/plan" icon="fa-solid fa-money-check-dollar" text="Payment Plan" />
                        </ul>
                    </Col>
                ) : ''}

            </Row>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(SidebarLarge);