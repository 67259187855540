import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Badge, CardFooter, Col, Progress, Row } from 'reactstrap';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const Home = ({ dashboard }) => {

    return (
        <div>
           <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>Pending Signatures</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className='border-info border rounded bg-secondary p-3'>
                        <Row>
                            <div className='col-auto' style={{width: 40}}>
                                <i className="fas fa-info-circle text-info" style={{fontSize: 24}} />
                            </div>

                            <Col>
                                <p className='mb-0'>Pending Signatures displays the documents requiring your attention.</p>
                                <p className='mb-0'>Review and sign the following documents to ensure timely progress on your case.</p>
                            </Col>
                        </Row>
                    </div>
                </CardBody>

                <CardBody>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Document Name</th>
                                    <th>Request Date</th>
                                    <th>Deadline</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Retainer Agreement</td>
                                    <td>Dec 27th, 2025</td>
                                    <td>Dec 31st, 2025</td>
                                    <td className='text-right'><button className='btn btn-outline-info'> Sign Document</button></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </CardBody>



            </Card>

           <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>Signed Documents</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className='border-info border rounded bg-secondary p-3'>
                        <p className='mb-0'>Keep track of all the documents you have signed throughout your case.</p>
                    </div>
                </CardBody>

                <CardBody>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Document Name</th>
                                    <th>Signature Date</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Retainer Agreement</td>
                                    <td>Dec 31st, 2025</td>
                                    <td className='text-right'><button className='btn btn-outline-warning'><i className="fas fa-download mr-2" /> Download</button></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </CardBody>



            </Card>
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
