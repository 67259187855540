import { connect } from 'react-redux';
import { Card, CardBody, Col, Row, CardFooter } from 'reactstrap';


import LogoNew from 'assets/img/logoNew.png';


const Home = ({ dashboard }) => {
    return (
        <Card className='archk-v2-header'>
            <CardBody className='py-4  d-none d-md-block'>
                <Row>
                    <div className='col-auto pr-5 pl-5 align-self-center' style={{width: 255}}>
                        <img alt="Fairmax Law" src={LogoNew} style={{width: '100%'}} />
                    </div>

                    <Col className='align-self-center border-left pl-5'>
                        <Row>
                            <Col>
                                <h2 className='mb-0 display-4'>
                                <b className='archk-v2-name-circle'>J</b>
                                    Hi, John
                                </h2>
                            </Col>

                            <div className='col-auto align-self-center text-right pr-5' style={{width: 250}}>
                                <span className='archk-v2-header-icon'><i className="fas fa-info-circle text-warning" /></span>
                                <span className='archk-v2-header-icon'><i className="fa-regular fa-comments" /></span>
                                <span className='archk-v2-header-icon'><i className="fa-regular fa-bell" /></span>
                                <span className='archk-v2-header-icon'><i className="fa-regular fa-user" /></span>
                            </div>

                        </Row>
                    </Col>
                </Row>
            </CardBody>

            <CardBody className='py-4  d-block d-md-none'>
                <Row>
                    <div className='col-auto  align-self-center' style={{width: 160}}>
                        <img alt="Fairmax Law" src={LogoNew}  />
                    </div>

                    <Col className='text-right pl-0 pr-0'>
                        <div className='col-auto align-self-center text-right pr-' >
                           
                            <span className='archk-v2-header-icon'><i className="fa-regular fa-comments" /></span>
                            <span className='archk-v2-header-icon'><i className="fa-regular fa-bell" /></span>
                            <span className='archk-v2-header-icon'><i className="fa-regular fa-user" /></span>
                        </div>
                            
                    </Col>

                </Row>

                
            </CardBody>

            <CardFooter>
                <h2 className='mb-0 display-4 mb-0'>
                <b className='archk-v2-name-circle'>J</b>
                    Hi, John

                    <span className='archk-v2-header-icon float-right'><i className="fas fa-info-circle text-warning" /></span>
                </h2>
            </CardFooter>

            {/* <CardFooter className="bg-danger text-center">
                <p className='mb-0 text-white'><i className="fas fa-exclamation-triangle text-center mr-2" /> Action Required! We are currently awaiting your action before we can proceed with your case. <b className='text-underline'>MORE INFO</b></p>
            </CardFooter> */}
        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
