import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Progress, Row } from 'reactstrap';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const Home = ({ dashboard }) => {

    const ProgressBar = ({ percent, title, color, opacity }) => (
        <Row className='mb-3' style={{opacity: opacity ? opacity : 1}}>
            <div className="col-auto text-left" style={{width: 65}}>
                <span className='text-white'>{percent}%</span>
            </div>
            <Col>
                <div className='col-auto align-self-center'>
                    <div className={`progress-`}>

                        <Progress max="100" value={percent} color={color} style={{ height: 18, borderRadius: 20, marginBottom: 0}}  />
                        <p className='text font-weight text-white mb-0 mt-2'>{title}</p>
                    </div>
                </div>

            </Col>
        </Row>

    )

    return (
        <Card style={{overflow: 'hidden'}}>
            <Row>
                <Col sm={12} md={6} lg={7} xl={7}>
                    <CardHeader>
                        <CardTitle className='mb-0'>Current Case Status</CardTitle>
                    </CardHeader>
                    <CardBody>


                        <h2>Info, Docs, Credit & Counseling</h2>

                        <p>This stage involves gather some documents and information from you. Generally this stage takes a few days but it can vary based on your unique situation.</p>

                    

                        <Row className='pt-3 pb-4'>
                            <Col className='align-self-center'>

                            <div className="border bg-gradient-success rounded px-4 py-4 mb-4">
                                <p className='font-weight-bold mb-0 text-white'><i style={{fontSize: 24, position: 'relative', top: 2}} className="fas fa-info-circle mr-3 " /> Gather Financial Information</p>
                            </div>
                            
                            <div className="border border-warning rounded px-4 py-4 mb-4">
                                <p className='font-weight-bold mb-0'><i style={{fontSize: 24, position: 'relative', top: 2}} className="fa-solid fa-file-import mr-3 text-warning" /> Upload Documents</p>
                            </div>
                        
                            <div className="border border-warning rounded px-4 py-4 mb-4">
                                <p className='font-weight-bold mb-0'><i style={{fontSize: 24, position: 'relative', top: 2}} className="fa-solid fa-video mr-3 text-warning" /> Take Credit Counseling Course</p>
                            </div>
                            
                            </Col>

                            <div className='col-auto align-self-center px-5 pl-5 pb-4' style={{width: 250}}>
                                <CircularProgressbar 
                                    value={50} 
                                    text={"50%"} 
                                    strokeWidth={12}
                                    styles={buildStyles({
                                        rotation: 0.5,
                                        textSize: '16px',
                                        pathTransitionDuration: 0.5,
                                        pathColor: `#144470`,
                                        textColor: '#32325d',
                                    })}
                                />
                            </div>
                        </Row>

                    </CardBody>
                </Col>

                <Col sm={12} md={6} lg={5} xl={5} className='bg-gradient-info-dark' style={{borderTopRightRadius: 8, borderBottomRightRadius: 8}}>
                    <div className='p-4 text-right'>
                        <p className='text-success'>Monitor the progress of your case and stay informed about the next steps.</p>

                        <ProgressBar 
                            percent={100}
                            title="1. Financial Consultation"
                            color="success"
                        />
                        <ProgressBar 
                            percent={100}
                            title="2. Online Account Setup"
                            color="success"
                        />
                        <ProgressBar 
                            percent={30}
                            title="3. Info, Docs, Credit & Counseling"
                            color="info-light"
                        />
                        <ProgressBar 
                            opacity={.9}
                            percent={0}
                            title="4. Case Preparation"
                            color="info"
                        />
                        <ProgressBar 
                            opacity={.7}
                            percent={0}
                            title="5. Attorney Review"
                            color="info"
                        />
                        <ProgressBar 
                            opacity={.5}
                            percent={0}
                            title="6. Case Filed"
                            color="info"
                        />


                    </div>
                </Col>
            </Row>

    </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
