import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row, Progress } from 'reactstrap';
import { useLocation } from 'react-router-dom'

import LogoNew from 'assets/img/logoNew.png';

const Welcome = ({ dashboard }) => {
    const location = useLocation();

    let percent = 25;
    let step = 1

    if(location.pathname.includes('/2')) {
        percent =  50;
        step = 2
    } else  if(location.pathname.includes('/3')) {
        percent =  75;
        step = 3
    } else  if(location.pathname.includes('/4')) {
        percent =  100;
        step = 4
    }

    return (
       
        <Container className='mt-3' fluid>

            <Card>
                <CardBody className='py-4'>
                    <Row>
                        <div className='col-auto pr-5 pl-5 align-self-center' style={{width: 255}}>
                            <img alt="Fairmax Law" src={LogoNew} style={{width: '100%'}} />
                        </div>

                        <Col className='align-self-center border-left pl-5'>
                            <Row>
                                <Col className='align-self-center'>
                                    <h2 className='mb-0 display-4'>
                                    <b className='archk-v2-name-circle'>J</b>
                                        Hi, John!
                                    </h2>
                                </Col>
                                <div className='col-auto align-self-center' style={{width: 500, maxWidth: '100%'}}>
                                    <div className="progress-wrapper border rounded bg-secondary px-4 py-3 custom-shadow- pt-3">
                                        <div className={`progress-info mb-0 mt--2`}>
                                            <div className="progress-label">
                                                <span className={`bg-white font-weight-bold text-sm ml--4 text-dark text-capitalize`} s>
                                                Step {step} / 4
                                                </span>
                                            </div>
                                            <div className="progress-percentage">
                                                <span>{percent}%</span>
                                            </div>
                                        </div>
                                        <Progress max="100" value={percent < 10 ? 5 : percent} color="success" />
                                    </div>
                                </div>

                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </Container>
        

    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Welcome);
