import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Badge, CardFooter, Col, Progress, Row } from 'reactstrap';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const Home = ({ dashboard }) => {

    return (
        <Card>
            <CardHeader>
                <CardTitle className='mb-0'>Creditor History</CardTitle>
            </CardHeader>

            <CardBody>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Creditor's Name</th>
                                <th>Date Submitted</th>
                                <th>Amount Owed</th>
                                <th className='text-right'>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>ABC Bank</td>
                                <td>Dec 27th, 2025</td>
                                <td>$543</td>
                                <td className='text-right'><Badge color="success" >Confirmed</Badge></td>
                            </tr>
                            <tr>
                                <td>ABC Bank</td>
                                <td>Dec 27th, 2025</td>
                                <td>$543</td>
                                <td className='text-right'><Badge color="success" >Confirmed</Badge></td>
                            </tr>
                            <tr>
                                <td>ABC Bank</td>
                                <td>Dec 27th, 2025</td>
                                <td>$543</td>
                                <td className='text-right'><Badge color="success" >Confirmed</Badge></td>
                            </tr>
                            <tr>
                                <td>ABC Bank</td>
                                <td>Dec 27th, 2025</td>
                                <td>$543</td>
                                <td className='text-right'><Badge color="success" >Confirmed</Badge></td>
                            </tr>
                            <tr>
                                <td>ABC Bank</td>
                                <td>Dec 27th, 2025</td>
                                <td>$543</td>
                                <td className='text-right'><Badge color="warning" >Pending</Badge></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardBody>

            <CardFooter className='text-center'>
                <button className='btn btn-lg btn-info'>
                    Add Creditor <i className="fas fa-plus ml-2" />
                </button>
            </CardFooter>

        </Card>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
