import { connect } from 'react-redux'
import { Col, Row, Card, CardBody, CardTitle, CardHeader, CardFooter, Progress } from 'reactstrap'
import { Helmet } from 'react-helmet';

import { useState } from 'react'



import CaseStatus from './CaseStatus'
import CaseManager from './CaseManager'
import Info from './Info'
import Documents from './Documents'
import CreditCounseling from './CreditCounseling'
import Support from './Support'
import Event from './Event'

const Home = ({ dashboard }) => {

    return (
        <div>

            <Helmet>
                <title>Fairmax Law</title>
                <meta name="description" content="Fairmax Law" />
            </Helmet>

            <Row>
                <Col md={12} lg={12}  xl={9}>
                    <CaseStatus />

                    <Info />

                    <Row>
                        <Col md={4}>
                            <Documents />
                        </Col>
                        <Col md={4}>
                            <CreditCounseling />
                        </Col>
                        <Col md={4}>
                            <Support />
                        </Col>
                    </Row>
                </Col>

                <Col md={12} lg={12} xl={3}>
                    <Event />
                    <CaseManager />

                </Col>
            </Row>

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
