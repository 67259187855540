import { connect } from 'react-redux'
import { Col, Row, Card, CardBody, CardTitle, CardHeader, CardFooter, Progress } from 'reactstrap'
import { Helmet } from 'react-helmet';

import { useState } from 'react'

// Employment
// Property
// Debts
// FINANCIAL ACCOUNTS
// LEGAL ACTIONS
// ASSETS
// Family
// DONATIONS & TRANSFERS
// BUSINESSES
// GOVT ASSISTANCE
// EXPENSES

import Employment from './Employment'

const Controller = ({ sidebar }) => {

    const [active, setActive] = useState(sidebar[0])

    return (
        <Card>
            <CardHeader>

                <Row>
                    <div className='col-auto align-self-center' style={{width: 225}}>
                        <CardTitle className="mb-0">My Case Details</CardTitle>
                    </div>
                    <Col className='align-self-center'>
                        <div className="progress-wrapper pt-3">
                            <div className={`progress-info mb-0 mt--2`}>
                                <div className="progress-label">
                                    <span className={`bg-white font-weight-bold text-sm ml--4 text-dark text-capitalize`} style={{ position: 'relative', left: -4}}>
                                    {active.title} Progress
                                    </span>
                                </div>
                                <div className="progress-percentage">
                                    <span>{100}%</span>
                                </div>
                            </div>
                            <Progress max="100" value={100} color="success" />
                        </div>
                    </Col>
                </Row>

            
            </CardHeader>


                <Row className='archk-v2-internal-sidebar'>
                    <div className='col-auto archk-v2-internal-sidebar-left' style={{width: 225}}>
                        <ul>
                            {sidebar.map((s, i) => (
                                <li onClick={() => setActive(s)} key={i} className={active.title === s.title ? 'active cursor-pointer' : 'cursor-pointer'}>
                                    {s.complete ? (
                                        <span className='archk-v2-internal-sidebar-icon text-gradient-success'>
                                            <i className="fas fa-check-circle mr-2" />
                                        </span>
                                    ) : (
                                        <span className='archk-v2-internal-sidebar-icon text-warning'>
                                            <i className="fas fa-info-circle mr-2" />
                                        </span>
                                    )}
                                    {s.title}
                                </li>
                            ))}
                           
                        </ul>
                    </div>

                    <Col className=' border-left pl-5 bg-secondary'>
                        {active.component ? <active.component /> : ''}
                    </Col>
                </Row>

        </Card>
    )
}

const Home = ({ dashboard }) => {
    return (
        <div>

            <Helmet>
                <title>My Dashboard</title>
                <meta name="description" content="My Dashboard" />
            </Helmet>

            <Controller 
                sidebar={[
                    { title: 'Employment', component: Employment, complete: true },
                    { title: 'Family', complete: true },
                    { title: 'Property' },
                    { title: 'Financial Accounts' },
                    { title: 'Assets' },
                    { title: 'Debts' },
                    { title: 'Transfers' },
                    { title: 'Legal Actions' },
                    { title: 'Businesses' },
                    { title: 'Govt Assistance' },
                    { title: 'Expenses' },
                ]}
            />

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Home);
